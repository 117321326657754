import { getLanguageMeta } from '@root/ui/utils'
import { apiGetHelper, apiGetorDeleteHelper, apiPutOrPostHelper, getDomain } from '@utils/helper'
import logError from '@utils/logError'

export const fetchItineary = ({
    itineraryId,
    host,
    isSsr = false,
    headers = {},
    signal
}: {
    itineraryId: string
    host: string
    isSsr?: boolean
    headers?: any
    signal: AbortSignal
}) => {
    try {
        const URL = `${getDomain(isSsr, host)}/itin/itinerary/${itineraryId}`
        const response = apiGetHelper(URL, null, headers, signal)
        return response
    } catch (e) {
        console.log(e)
        logError(e)
    }
}

const getMerchandisingQueryFK = (isIntl, tripType) => {
    const type = isIntl ? 'intl' : 'dom'
    return `${type}_${tripType.toLowerCase()}`
}
export const fetchItinearyOffersData = async ({ isRT, isIntl }) => {
    try {
        const { locale = '' } = getLanguageMeta() || {}
        const tripType = isRT ? 'RT' : 'OW'
        let appAgent = 'pwa'
        const isProdPath = process.env.PWA_ENV === 'production' ? 'prod' : 'prod'
        const ios = window?.iosData?.['app-agent'] || ''
        const android = window?.androidData?.['app-agent'] || ''
        // if (ios) appAgent = 'iphoneapp'
        // if (android) appAgent = 'androidapp'

        const query = `${getMerchandisingQueryFK(
            isIntl,
            tripType
        )}_${locale.toLowerCase()}.json?${new Date().getTime()}`
        const URL = `/offermgmt/fkMarketing/${isProdPath}/flights_srp_itin_${appAgent}_${query}`
        const response = apiGetHelper(URL, '', {}, null)
        return response
    } catch (e) {
        console.log(e)
        logError(e)
    }
}

export const getTransperancyWidgetBulkData = async (itineraryId: string, signal: AbortSignal) => {
    let URL = `/itin/${itineraryId}/v2/fare-family-info`
    const response = apiGetHelper(URL, null, null, signal)
    return response
}

export const selectFareFamily = payload => {
    try {
        const URL = `/itin/fare-card/update`
        const response = apiPutOrPostHelper(URL, payload, 'application/json', {})
        return response
    } catch (e) {
        console.log(e)
    }
}

export const fetchFareRules = async (itineraryId: string, signal: AbortSignal) => {
    try {
        const URL = `/itin/api/itinerary/${itineraryId}/info/flightInfoV3`
        const response = apiGetHelper(URL, null, null, signal)
        return response
    } catch (e) {
        console.log(e)
        logError(e)
    }
}

export const fetchAncillaryData = async (itineraryId: string, controller: AbortController) => {
    try {
        const { signal } = controller
        const URL = `/itin/v2/${itineraryId}/ancillaries/fetch?includeBaggage=true&includeMeal=true&includeSeat=true`
        const response = apiGetHelper(URL, null, null, signal)
        return response
    } catch (e) {
        console.log(e)
        logError(e)
    }
}

export const itineraryUpdateApi = (payload: any) => {
    try {
        const URL = `/itin/itinerary/update`
        const response = apiPutOrPostHelper(URL, payload, 'application/json', '')
        return response
    } catch (e) {
        console.log(e)
        logError(e)
    }
}

export const applyCoupon = async (payload, itineraryId) => {
    try {
        const URL = `/itin/itinerary/${itineraryId}/applyCoupon`
        const responseData = await apiPutOrPostHelper(URL, payload, 'application/json', '')
        return responseData
    } catch (e) {
        console.log('Coupon Exception ', e)
        logError(e)
    }
}

export const applyShukran = payload => {
    try {
        const URL = `/freqFlyerService/v1/fareKeys/loyaltyRewardsPoint`
        const response = apiPutOrPostHelper(URL, payload, 'application/json', '')
        return response
    } catch (e) {
        console.log(e)
        logError(e)
    }
}

export const applyVoucher = (payload, itineraryId) => {
    try {
        const URL = `/itin/itinerary/${itineraryId}/applyVoucher`
        const response = apiPutOrPostHelper(URL, payload, 'application/json', '')
        return response
    } catch (e) {
        console.log(e)
        logError(e)
    }
}

export const removeDiscount = ({ itineraryId, payload, revalidate }) => {
    try {
        const URL = `/itin/${itineraryId}/remove-discount`
        let requestHeader = { 'X-CT-KEY': true }
        // if (revalidate) {
        //     requestHeader = {
        //         ...requestHeader,
        //         'X-REMOVE': false
        //     }
        // }
        const response = apiPutOrPostHelper(URL, payload, 'application/json', requestHeader, 'put')
        return response
    } catch (e) {
        console.log(e)
        logError(e)
    }
}

export const removeVoucher = ({ itineraryId, payload }) => {
    try {
        const URL = `/itin/itinerary/${itineraryId}/remove-voucher`
        const requestHeader = { 'X-CT-KEY': true }
        const response = apiPutOrPostHelper(URL, payload, 'application/json', requestHeader, 'put')
        return response
    } catch (e) {
        console.log(e)
        logError(e)
    }
}

export const updateAddons = async (payload, itineraryId) => {
    try {
        const URL = `/itin/v1/${itineraryId}/ancillaries/update`
        const responseData = apiPutOrPostHelper(URL, payload, 'application/json', {}, 'put')
        return responseData
    } catch (e) {
        console.log(e)
        logError(e)
    }
}

export const deleteAddOnData = itineraryId => {
    try {
        const URL = `/itin/v1/${itineraryId}/ancillaries/remove-all`
        const response = apiGetorDeleteHelper(URL, null, 'delete')
        return response
    } catch (e) {
        console.log(e)
        logError(e)
    }
}

export const fetchUserProfileData = (itineraryId: string, signal: AbortSignal) => {
    try {
        const URL = `/itin/v2/itinerary/${itineraryId}/user`
        const response = apiGetorDeleteHelper(URL, '', 'get', {}, signal)
        return response
    } catch (e) {
        console.log(e)
        logError(e)
    }
}

export const updateTravellers = payload => {
    try {
        const URL = `/itin/itinerary/update`
        const response = apiPutOrPostHelper(URL, payload, 'application/json', {})
        return response
    } catch (e) {
        console.log(e)
        logError(e)
    }
}

export const itineraryPrePaymentApi = payload => {
    try {
        const URL = `/itin/itinerary/${payload.itineraryId}/prepayment`
        const response = apiPutOrPostHelper(URL, payload, 'application/json', {})
        return response
    } catch (e) {
        console.log(e)
        logError(e)
    }
}

export const userSignIn = payload => {
    try {
        const URL = `/v2/signin`
        const requestHeaders = {
            'X-CT-SOURCETYPE': 'web',
            service: window.location.pathname
        }
        const response = apiPutOrPostHelper(URL, payload, 'application/json', requestHeaders)
        return response
    } catch (e) {
        console.log(e)
        logError(e)
    }
}
