import React, { useState } from 'react'
import './tooltip.scss'

const Tooltip = props => {
    let timeout
    const {
        isActive = false,
        direction,
        content,
        className = '',
        style = {},
        wrapperStyle = { marginTop: '2px', top: '3px' }
    } = props || {}
    const [active, setActive] = useState(isActive)

    const showTip = () => {
        timeout = setTimeout(() => {
            setActive(true)
        })
    }

    const hideTip = () => {
        clearInterval(timeout)
        setActive(false)
    }
    return (
        <div
            className="Tooltip-Wrapper mb-1 pl-1"
            style={wrapperStyle}
            onClick={active ? hideTip : showTip}
            onMouseLeave={hideTip}
        >
            {props.children}
            {active && (
                <div className={`Tooltip-Tip p-3x ${direction || 'top'} ${className}`} style={style}>
                    {content}
                </div>
            )}
        </div>
    )
}

export default Tooltip
