/*eslint-disable*/
import ClevertapReact from './Clevertap'
import { path, getCookie, checkDeviceType, isEmpty, isUserSignedIn, getBitBucketBuildNumber } from '@ui/utils'
import { ClevertapAndroid, ClevertapIos } from './mobileAppAnalytics'
import { isServer } from '@utils/appAgent'
import { checkInternalCTUser } from '@utils/flights'
import { sendS2SEvent } from '@api/resultsPage'
import { v4 as uuid } from 'uuid'

const domain = path(['location', 'hostname'], typeof window !== 'undefined' ? window : {}) || []
const CLEVERTAP_KEY = process.env.NEXT_PUBLIC_CLEVERTAP_KEY || {}
const CLEVERTAP_KEY_ID = CLEVERTAP_KEY || null

class Analytics {
    constructor() {
        this.enabled = false
        this.abData = {}
        if (CLEVERTAP_KEY_ID && typeof window !== 'undefined') {
            this.enabled = true
            ClevertapReact.initialize(CLEVERTAP_KEY_ID)
        }
    }
    event = (name, payload = {}) => {
        let ctAb = isEmpty(getCookie('ct-pwa')) ? {} : JSON.parse(decodeURIComponent(getCookie('ct-pwa')))
        const whiteListEventNames = ['Charged', 'Air_PageLoad']
        payload.platform = payload.platform || 'PWA'
        payload.domainName = domain || 'NA'
        payload.locationPath = isServer() ? '' : window.location.pathname
        payload.is_logged_in = isUserSignedIn() ? 'yes' : 'no'
        let deviceType = checkDeviceType()
        if (typeof navigator != 'undefined' && navigator?.connection) {
            payload.rtt = navigator?.connection?.rtt
            payload.effectiveType = navigator?.connection?.effectiveType
            payload.downlink = navigator?.connection?.downlink
        } else {
            console.log('Navigator Connection API not supported')
        }

        if (deviceType === 'iPhoneApp') {
            const appVersion = path(['iosData', 'app-version-name'], window) || 'N/A'
            payload.platform = 'iOS'
            payload.appType = 'webView'
            payload.app_version = appVersion
        }
        if (deviceType === 'AndroidApp') {
            const appVersion = path(['androidData', 'app-version-name'], window) || 'N/A'
            payload.platform = 'android'
            payload.appType = 'webView'
            payload.app_version = appVersion
        }
        payload.is_logged_in = isUserSignedIn() ? 'yes' : 'no'
        payload.isCtLoginUser = checkInternalCTUser()
        payload.isNextFlow = 'true'
        payload.ssrEnabled_abcookie = getCookie('ssrEnabled') || 'N/A',
        payload.fareFamily_abcookie = ctAb?.fareFamily || 'N/A'
        payload.newTupple_abcookie = ctAb?.newTupple || 'N/A',

        setTimeout(() => {
            if (whiteListEventNames.includes(name)) {
                const s2sPayload = {
                    id: uuid(),
                    schemaName: `userEvents.${name.toLowerCase()}`,
                    schemaVersion: '0.1',
                    timestamp: new Date().getTime(),
                    tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
                    data: payload
                }
                sendS2SEvent(s2sPayload)
            }
            if (deviceType) {
                deviceType === 'AndroidApp' ? ClevertapAndroid.event(name, payload) : ClevertapIos.event(name, payload)
            } else {
                ClevertapReact.event(name, payload)
            }
        }, 300)
    }
    customEvent = (name, payload = {}) => {
        const ctAb = isEmpty(getCookie('ct-pwa')) ? '{}' : decodeURIComponent(getCookie('ct-pwa'))
        if (this.enabled) {
            this.abData = JSON.parse(ctAb)
            payload.platform = payload.platform || 'PWA'
            payload.locationPath = window.location.pathname
            payload.domainName = domain || 'NA'
            payload.language = getCookie('selectedLanguage') ? getCookie('selectedLanguage') : payload.language
            payload.is_logged_in = isUserSignedIn() ? 'yes' : 'no'
            let deviceType = checkDeviceType()
            if (deviceType === 'iPhoneApp') {
                const appVersion = path(['iosData', 'app-version-name'], window) || 'N/A'
                payload.platform = 'iOS'
                payload.appType = 'webView'
                payload.app_version = appVersion
            }
            if (deviceType === 'AndroidApp') {
                const appVersion = path(['androidData', 'app-version-name'], window) || 'N/A'
                payload.platform = 'android'
                payload.appType = 'webView'
                payload.app_version = appVersion
            }
            if (payload.apiError) {
                payload.abApiError = true
            }
            payload.abResponse_abcookie = JSON.stringify(payload)
            payload.ctAbResponse_abcookie = ctAb
            setTimeout(() => {
                if (deviceType) {
                    deviceType === 'AndroidApp'
                        ? ClevertapAndroid.event(name, payload)
                        : ClevertapIos.event(name, payload)
                } else {
                    ClevertapReact.event(name, payload)
                }
            }, 300)
        }
    }
    profile = payload => {
        if (this.enabled) {
            ClevertapReact.profile(payload)
        }
    }
    logout = () => {
        if (this.enabled) {
            ClevertapReact.logout()
        }
    }
}

const AnalyticsObject = new Analytics()
export default AnalyticsObject
