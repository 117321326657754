export type Colors = Readonly<{
    neutral900: string
    neutral700: string
    neutral500: string
    neutral300: string
    neutral100: string
    brand: string
    link: string
    link2: string
    success: string
    alert: string
    warning: string
    line: string
    brandBg: string
    linkBg: string
    successBg: string
    alertBg: string
    warningBg: string
    brandText: string
    successText: string
    alertText: string
    warningText: string
    disabledText: string
    linkText: string
    yellowSecondary: string
    bluePrimary500: string
    successGreen: string
    bgGreen: string
    defaultTheme2_800: string
    defaultTheme2_50: string
    primaryNeutral900: string
    secondaryNeutral900: string
    disabledNeutral500: string
    lineDarkNeutral300: string
    appBackground: string
    surfaceBackground: string
    defaultFkPrimary50: string
    error600: string
    error50: string
    alertWarning600: string
    warningFK: string
    linkBg2: string
    orangePrimary: string
}>

const colors: Colors = {
    neutral900: '#1A1A1A',
    neutral700: '#808080',
    neutral500: '#B3B3B3',
    neutral300: '#F3F3F3',
    neutral100: '#FFFFFF',
    brand: '#FF4F17',
    link: '#0F6BFF',
    link2: '#3366CC',
    success: '#11A670',
    alert: '#DF9641',
    warning: '#C83232',
    line: '#E7E7E7',
    brandBg: '#FEF3ED',
    linkBg: '#EFF3FB',
    successBg: '#ECF8F4',
    alertBg: '#FCF7F0',
    warningBg: '#FBEFEF',
    brandText: '#FF4F17',
    successText: '#11A670',
    alertText: '#DF9641',
    warningText: '#C83232',
    disabledText: '#B3B3B3',
    linkText: '#36c',

    yellowSecondary: '#FFC200',
    bluePrimary500: '#2A55E5',
    successGreen: '#108934',
    bgGreen: '#E7F8EC',
    defaultTheme2_800: '#770033',
    defaultTheme2_50: '#F9E5EE',
    primaryNeutral900: '#212121',
    secondaryNeutral900: '#666666',
    disabledNeutral500: '9E9E9E',
    lineDarkNeutral300: '#E0E0E0',
    appBackground: '#F5F5F5',
    surfaceBackground: '#FFFFFF',
    defaultFkPrimary50: '#F0F5FF',
    error600: '#D23C33',
    error50: '#FBEBED',
    alertWarning600: '#E89910',
    warningFK: '#FEF7E9',

    linkBg2: '#eff5fc',
    orangePrimary: '#f66826'
}

export default colors
