import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../configureStore'
import { getAbConfig } from 'src/api/abConfig'
import { defaultCookieValues } from '@constants/flights/index'
export interface AbTestDataState {
    abConfigData?: Record<string, string>
    isApiResolved: boolean
}
const initialState: AbTestDataState = {
    abConfigData: { ...defaultCookieValues },
    isApiResolved: false
}

export const fetchAbTestData = createAsyncThunk('abConfig/abTest', async () => {
    try {
        const response = await getAbConfig()
        return response
    } catch (e) {
        return {}
    }
})

export const abConfigSlice = createSlice({
    name: 'abConfig',
    initialState,
    reducers: {
        setAbData: (state, action) => {
            state.abConfigData = action.payload
        }
    },
    extraReducers(builder) {
        builder.addCase(fetchAbTestData.fulfilled, (state, action: PayloadAction<any>) => {
            state.abConfigData = { ...defaultCookieValues, ...action.payload.data } || { ...defaultCookieValues }
            state.isApiResolved = true
        })
        builder.addCase(fetchAbTestData.rejected, (state, action) => {
            state.abConfigData = { ...defaultCookieValues }
            state.isApiResolved = true
        })
    }
})

const getAbConfigData = (state: RootState) => state.abConfig.abConfigData
const getAbApiResolvedStatus = (state: RootState) => state.abConfig.isApiResolved

const { reducer, actions } = abConfigSlice
const { setAbData } = actions || {}

export { setAbData, getAbApiResolvedStatus, getAbConfigData }

export default reducer
